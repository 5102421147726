<template>
    <Header />
</template>

<script>
    import Header from './views/Header.vue';

    export default {
        components: { Header }
    };
</script>

<style scoped></style>
