<template>
    <div class="header-pp">
        <div class="d-header" v-if="!isMobileComputed">
            <div class="d-header-a">
                <div class="d-sector-a">
                    <!-- Menu links -->

                    <div class="menu-links" v-show="menuDesktopLinksVisible">
                        <div class="company">
                            <div class="legend">Institucional</div>
                            <ul class="panel">
                                <li
                                    class="item"
                                    v-for="({ slug, title }, i) in linksSubMenu"
                                    :key="i">
                                    <a :href="linkCompanyComputed(slug)" :title="title">
                                        {{ title }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- Menu search -->

                    <div class="menu-search" v-show="menuDesktopSearchVisible">
                        <form :action="linkBase" id="cse-search-box" class="form-search">
                            <input
                                name="cx"
                                type="hidden"
                                value="003443162353542833581:nvbkfyqhv9q" />
                            <input name="cof" type="hidden" value="FORID:11" />
                            <input name="ie" type="hidden" value="UTF-8" />
                            <input
                                type="text"
                                id="tx-busca-topo"
                                v-model="valueSearch"
                                name="q"
                                maxlength="50"
                                placeholder="O que busca?"
                                class="text" />
                            <input
                                type="submit"
                                value="Buscar"
                                class="button"
                                @click="submitFormSearch($event)" />
                        </form>
                    </div>

                    <!-- Buttons -->

                    <a class="menu" href="#" @click="toggleDesktopMenuLinks()"> </a>
                    <a class="search" href="#" @click="toggleDesktopMenuSearch()"></a>
                    <div class="logo">
                        <a :href="linkBase">
                            <img
                                src="../assets/logo-pragmatismo.png"
                                :alt="titleSector"
                                :title="titleSector" />
                        </a>
                    </div>
                </div>
                <div class="d-sector-b">
                    <a class="help" :href="helpComputed" title="apoie!"> Apoie </a>
                    <div class="media">
                        <div class="legend">Siga nossas redes</div>
                        <ul class="social">
                            <li
                                class="item"
                                v-for="({ link, cssClass, title }, i) in linksSociais"
                                :key="i">
                                <a :href="link" :class="cssClass" :title="title"></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="d-header-b">
                <ul class="d-menu">
                    <li class="d-item" v-for="item in linksMenu" :key="item.slug">
                        <a :href="linkMenuComputed(item)">{{ item.title }}</a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- mobile -->
        <div class="m-header" v-else>
            <div class="m-header-a">
                <a :href="linkBase" class="logo">
                    <img
                        src="../assets/logo-pragmatismo.png"
                        :alt="titleSector"
                        :title="titleSector" />
                </a>
                <a class="help" :href="helpComputed" title="apoie!"> Apoie </a>
            </div>
            <div class="m-header-b">
                <div class="header-b-left">
                    <div
                        class="box-container"
                        v-show="menuMobileLinksVisible || menuMobileSearchVisible">
                        <div class="container-content">
                            <!-- Menu links -->

                            <div class="menu-links" v-show="menuMobileLinksVisible">
                                <div class="company">
                                    <div class="legend">Institucional</div>
                                    <ul class="panel">
                                        <li
                                            class="item"
                                            v-for="({ slug, title }, i) in linksSubMenu"
                                            :key="i">
                                            <a
                                                :href="linkCompanyComputed(slug)"
                                                :title="title">
                                                {{ title }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <!-- Menu search -->

                            <div class="menu-search" v-show="menuMobileSearchVisible">
                                <form
                                    :action="linkBase"
                                    id="cse-search-box"
                                    class="form-search">
                                    <input
                                        name="cx"
                                        type="hidden"
                                        value="003443162353542833581:nvbkfyqhv9q" />
                                    <input name="cof" type="hidden" value="FORID:11" />
                                    <input name="ie" type="hidden" value="UTF-8" />
                                    <input
                                        type="text"
                                        id="tx-busca-topo"
                                        v-model="valueSearch"
                                        name="q"
                                        maxlength="50"
                                        placeholder="O que busca?"
                                        class="text" />
                                    <input
                                        type="submit"
                                        value="Buscar"
                                        class="button"
                                        @click="submitFormSearch($event)" />
                                </form>
                            </div>
                        </div>
                    </div>

                    <div class="b-left-item">
                        <a class="menu" href="#" @click="toggleMobileMenuLinks()"> </a>
                    </div>
                    <div class="b-left-item">
                        <a class="search" href="#" @click="toggleMobileMenuSearch()"></a>
                    </div>
                </div>
                <ul class="social">
                    <li
                        class="item"
                        v-for="({ link, cssClass, title }, i) in linksSociais"
                        :key="i">
                        <a :href="link" :class="cssClass" :title="title"></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Header',
        data() {
            return {
                menuDesktopLinksVisible: false,
                menuDesktopSearchVisible: false,
                menuMobileLinksVisible: false,
                menuMobileSearchVisible: false,
                valueSearch: null,
                titleSector: 'Pragmatismo Poítico',
                linkBase: 'https://www.pragmatismopolitico.com.br',
                logo: 'logo-pragmatismo.png',
                tagHelp: 'apoie',
                linksSociais: [
                    {
                        link: 'https://www.facebook.com/PragmatismoPolitico',
                        cssClass: 'facebook',
                        title: 'Facebook'
                    },
                    {
                        link: 'https://www.instagram.com/pragmatismopolitico/',
                        cssClass: 'instagram',
                        title: 'Instagram'
                    },
                    {
                        link: 'https://twitter.com/Pragmatismo_',
                        cssClass: 'twitter',
                        title: 'Twitter'
                    },
                    {
                        link: 'https://www.youtube.com/user/PragmatismoPolitico',
                        cssClass: 'youtube',
                        title: 'Youtube'
                    },
                    {
                        link: 'https://www.tiktok.com/@pragmatismoportal',
                        cssClass: 'tiktok',
                        title: 'TikTok'
                    }
                ],
                linksMenu: [
                    { slug: 'tag/politica', title: 'Política' },
                    { slug: 'tag/direitos-humanos', title: 'Direitos humanos' },
                    { slug: 'tag/educacao', title: 'Educação' },
                    { slug: 'tag/saude', title: 'Saúde' },
                    { slug: 'tag/economia', title: 'Economia' },
                    { slug: 'tag/cultura', title: 'Cultura' },
                    { slug: 'tag/mundo', title: 'Mundo' },
                    { slug: 'tag/meio-ambiente', title: 'Meio ambiene' },
                    { slug: 'tag/colunistas', title: 'Colunistas' }
                ],
                linksSubMenu: [
                    { slug: '2017/01/quem-somos.html', title: 'Quem somos' },
                    { slug: 'contatos', title: 'Contatos' }
                ]
            };
        },
        computed: {
            isMobileComputed() {
                return this.$mq === 'xs' ? true : false;
            },
            helpComputed() {
                return `${this.linkBase}/${this.tagHelp}`;
            },
            linkMenuComputed() {
                return (item) => {
                    return `${this.linkBase}/${item.slug}`;
                };
            },
            linkCompanyComputed() {
                return (slug) => {
                    return `${this.linkBase}/${slug}`;
                };
            }
        },
        methods: {
            submitFormSearch(e) {
                const myRegEx = /^[a-z0-9]+$/i;

                const isRegexValid = myRegEx.test(this.valueSearch);

                if (!this.valueSearch || this.valueSearch.length <= 3 || !isRegexValid) {
                    alert('Digite um valor para sua busca!');
                    e.preventDefault();
                }
            },
            toggleDesktopMenuLinks() {
                this.menuDesktopLinksVisible = !this.menuDesktopLinksVisible;

                if (this.menuDesktopLinksVisible) {
                    this.menuDesktopSearchVisible = false;
                }
            },
            toggleDesktopMenuSearch() {
                this.menuDesktopSearchVisible = !this.menuDesktopSearchVisible;

                if (this.menuDesktopSearchVisible) {
                    this.menuDesktopLinksVisible = false;
                }
            },
            toggleMobileMenuLinks() {
                this.menuMobileLinksVisible = !this.menuMobileLinksVisible;

                if (this.menuMobileLinksVisible) {
                    this.menuMobileSearchVisible = false;
                }
            },
            toggleMobileMenuSearch() {
                this.menuMobileSearchVisible = !this.menuMobileSearchVisible;

                if (this.menuMobileSearchVisible) {
                    this.menuMobileLinksVisible = false;
                }
            }
        }
    };
</script>

<style>
    @font-face {
        font-family: 'frederik';
        src: url('../assets/fonts/frederik.eot');
        src: url('../assets/fonts/frederik.woff') format('woff'),
            url('../assets/fonts/frederik.otf') format('opentype'),
            url('../assets/fonts/frederik.svg#frederik') format('svg');
    }

    .d-header {
        padding-left: 20px;
        padding-right: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    }

    .d-header .d-header-a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 1070px;
        margin: auto;
        border-bottom: 2px solid #f00000;
    }

    .d-header .d-header-a .d-sector-a {
        display: flex;
        align-items: center;
        position: relative;
    }

    .d-header .d-header-a .d-sector-a .menu {
        width: 18px;
        height: 18px;
        background-image: url('../assets/list.png');
        background-size: cover;
        margin-right: 20px;
    }

    .d-header .d-header-a .d-sector-a .menu-links {
        position: absolute;
        padding: 15px;
        border: 1px solid #ccc;
        top: 45px;
        left: 0px;
        background-color: #ffffff;
    }

    .d-header .d-header-a .d-sector-a .menu-links .company .legend {
        font-size: 18px;
        font-weight: bold;
        font-family: frederik;
    }

    .d-header .d-header-a .d-sector-a .menu-links .company .panel {
        margin-top: 10px;
    }

    .d-header .d-header-a .d-sector-a .menu-links .company .panel .item {
        margin-top: 10px;
    }

    .d-header .d-header-a .d-sector-a .menu-links .company .panel .item a:link {
        font-family: frederik;
        font-size: 16px;
        text-transform: underline;
        color: #000;
    }

    .d-header .d-header-a .d-sector-a .menu-links .company .panel .item a:hover {
        text-transform: none !important;
        color: red !important;
    }

    .d-header .d-header-a .d-sector-a .menu-links .company .panel .item a:visited {
        color: #000;
    }

    .d-header .d-header-a .d-sector-a .menu-search {
        position: absolute;
        padding: 5px;
        top: 45px;
        left: 40px;
        background-color: #ffffff;
    }

    .d-header .d-header-a .d-sector-a .menu-search .form-search .text {
        margin-right: 5px;
        font-size: 16px;
        height: 40px;
    }

    .d-header .d-header-a .d-sector-a .menu-search .form-search .button {
        padding: 5px;
        border: 0px;
        background-color: red;
        color: #fff;
    }

    .d-header .d-header-a .d-sector-a .search {
        width: 18px;
        height: 18px;
        background-image: url('../assets/search.png');
        background-size: cover;
        margin-right: 20px;
    }

    .d-header .d-header-a .d-sector-a .logo {
        margin-top: 7px;
        margin-bottom: 7px;
    }

    .d-header .d-header-a .d-sector-a .logo a img {
        float: left;
        height: 50px;
    }

    .d-header .d-header-a .d-sector-b {
        display: flex;
    }

    .d-header .d-header-a .d-sector-b .help:link {
        padding: 12px;
        padding-top: 15px;
        padding-bottom: 15px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
        background-color: #f00000;
        text-decoration: none;
        color: #ffffff;
        font-size: 18px;
        font-weight: 900;
        font-family: frederik;
        margin-right: 15px;
    }

    .d-header .d-header-a .d-sector-b .help:hover {
        background-color: #c10909;
    }

    .d-header .d-header-a .d-sector-b .help:visited {
        color: #ffffff;
    }

    .d-header .d-header-a .d-sector-b .media .legend {
        font-family: frederik;
        font-weight: bold;
        font-size: 14px;
        font-weight: bold;
        color: #f00000;
    }

    .d-header .d-header-a .d-sector-b .media .social {
        display: flex;
        margin-top: 7px;
    }

    .d-header .d-header-a .d-sector-b .media .social .item {
        margin-left: 10px;
    }

    .d-header .d-header-a .d-sector-b .media .social .item:first-child {
        margin-left: 0px;
    }

    .d-header .d-header-a .d-sector-b .media .social .item a {
        display: block;
        width: 25px;
        height: 25px;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .d-header .d-header-a .d-sector-b .media .social .item a.facebook {
        background-image: url('../assets/facebook.png');
    }

    .d-header .d-header-a .d-sector-b .media .social .item a.instagram {
        background-image: url('../assets/instagram.png');
    }

    .d-header .d-header-a .d-sector-b .media .social .item a.twitter {
        background-image: url('../assets/twitter.png');
    }

    .d-header .d-header-a .d-sector-b .media .social .item a.tiktok {
        background-image: url('../assets/tiktok.png');
    }

    .d-header .d-header-a .d-sector-b .media .social .item a.youtube {
        background-image: url('../assets/youtube.png');
    }

    .d-header .d-header-b {
        display: flex;
        align-items: center;
        width: 1070px;
        margin: auto;
        height: 40px;
    }

    .d-header .d-header-b .d-menu {
        display: flex;
    }

    .d-header .d-header-b .d-menu .d-item {
        margin-left: 20px;
    }

    .d-header .d-header-b .d-menu .d-item:first-child {
        margin-left: 0px;
    }

    .d-header .d-header-b .d-menu .d-item a:link {
        text-decoration: none;
        font-size: 17px;
        color: #000000;
        font-family: frederik;
        font-weight: bold;
    }

    .d-header .d-header-b .d-menu .d-item a:hover {
        text-decoration: underline;
    }

    .d-header .d-header-b .d-menu .d-item a:visited {
        color: #000000;
    }

    /* MOBILE */

    .m-header {
        margin: auto;
    }

    .m-header .m-header-a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 2px solid #f00000;
    }

    .m-header .m-header-a .logo {
        margin-left: 8px;
        margin-top: 7px;
        margin-bottom: 7px;
    }

    .m-header .m-header-a .logo img {
        float: left;
        height: 50px;
    }

    .m-header .m-header-a .help:link {
        margin-right: 8px;
        padding: 12px;
        padding-top: 15px;
        padding-bottom: 15px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
        background-color: #f00000;
        text-decoration: none;
        color: #ffffff;
        font-size: 18px;
        font-weight: 900;
        font-family: frederik;
    }

    .m-header .m-header-a .help:hover {
        background-color: #c10909;
    }

    .m-header .m-header-a .help:visited {
        color: #ffffff;
    }

    .m-header .m-header-b {
        position: relative;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #dddddd;
    }

    .m-header .m-header-b .header-b-left {
        display: flex;
    }

    .m-header .m-header-b .header-b-left .box-container {
        position: absolute;
        top: 42px;
        width: 100%;
    }

    .m-header .m-header-b .header-b-left .box-container .container-content {
        display: flex;
        padding: 20px;
        box-shadow: rgba(109, 109, 109, 0.16) 0px 3px 6px,
            rgba(115, 115, 115, 0.23) 0px 3px 6px;
    }

    .m-header .m-header-b .header-b-left .b-left-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-right: 1px solid #dddddd;
    }

    .m-header
        .m-header-b
        .header-b-left
        .box-container
        .container-content
        .menu-links
        .company
        .legend {
        font-size: 18px;
        font-weight: bold;
        font-family: frederik;
    }

    .m-header
        .m-header-b
        .header-b-left
        .box-container
        .container-content
        .menu-links
        .company
        .panel {
        display: flex;
        flex-direction: column;
    }

    .m-header
        .m-header-b
        .header-b-left
        .box-container
        .container-content
        .menu-links
        .company
        .panel
        .item {
        margin-top: 10px;
    }

    .m-header
        .m-header-b
        .header-b-left
        .box-container
        .container-content
        .menu-links
        .company
        .panel
        .item
        a:link {
        font-family: frederik;
        font-size: 16px;
        text-transform: underline;
        color: #000;
    }

    .m-header
        .m-header-b
        .header-b-left
        .box-container
        .container-content
        .menu-links
        .company
        .panel
        .item
        a:hover {
        text-transform: none !important;
        color: red !important;
    }

    .m-header
        .m-header-b
        .header-b-left
        .box-container
        .container-content
        .menu-links
        .company
        .panel
        .item
        a:visited {
        color: #000;
    }

    .m-header
        .m-header-b
        .header-b-left
        .box-container
        .container-content
        .menu-search
        .form-search
        .text {
        margin-right: 5px;
        font-size: 18px;
        font-family: frederik;
        height: 40px;
    }

    .m-header
        .m-header-b
        .header-b-left
        .box-container
        .container-content
        .menu-search
        .form-search
        .button {
        line-height: 40px;
        width: 70px;
        border: 0px;
        background-color: red;
        color: #fff;
    }

    .m-header .m-header-b .header-b-left .b-left-item .menu {
        display: block;
        width: 18px;
        height: 18px;
        background-image: url('../assets/list.png');
        background-size: cover;
        border-right: 1px solid #dddddd;
    }

    .m-header .m-header-b .header-b-left .b-left-item .search {
        display: block;
        width: 18px;
        height: 18px;
        background-image: url('../assets/search.png');
        background-size: cover;
    }

    .m-header .m-header-b .social {
        display: flex;
        margin-top: 7px;
        margin-right: 8px;
    }

    .m-header .m-header-b .social .item {
        margin-left: 10px;
    }
    .m-header .m-header-b .social .item:first-child {
        margin-left: 0px;
    }

    .m-header .m-header-b .social .item a {
        display: block;
        width: 25px;
        height: 25px;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .m-header .m-header-b .social .item a.facebook {
        background-image: url('../assets/facebook.png');
    }

    .m-header .m-header-b .social .item a.instagram {
        background-image: url('../assets/instagram.png');
    }

    .m-header .m-header-b .social .item a.twitter {
        background-image: url('../assets/twitter.png');
    }

    .m-header .m-header-b .social .item a.tiktok {
        background-image: url('../assets/tiktok.png');
    }

    .m-header .m-header-b .social .item a.youtube {
        background-image: url('../assets/youtube.png');
    }
</style>
